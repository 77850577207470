// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
import "../../assets/stylesheets/reset.css"

import { createApp } from 'vue/dist/vue.esm-bundler';  
import { createI18n } from 'vue-i18n'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import Root from '../components/Root.vue'  

import x from "../translation/translate.js";

const translations = x.messages;

const i18n = createI18n({
    locale: 'lv',
    fallbackLocale: 'en',
    messages: translations
  })

const vuetify = createVuetify({
  components,
  directives,
})

const app = createApp({  
  data() {  
    return {  
      text: 'Vue 3 lives here'  
    }  
  }  
})  
///////////////////////////////////////////////////////

app.component('Root', Root)  
app.use(i18n)
app.use(vuetify)
  
app.mount('#app');  
console.log("app", app);  