const messages = {
    en: {
        title: "Product Scanner",
        wait: "Waiting for product scan",
        more_info: "More Info",
        scan: "Scan New"
    },
    lv: {
        title: "Skeneris",
        wait: "Gaida produkta skenēšanu",
        more_info: "Vairāk",
        scan: "Skenēt"
    }
}

export default { messages };