<script>
    import { QrStream, QrCapture, QrDropzone } from 'vue3-qr-reader'; 
    import axios from 'axios';

    export default {
        components: {
            QrStream,
            QrCapture,
            QrDropzone
        },
        data() {
            return {
                state_scan: true,
                current_sku: null,
                product_data: null,
                has_product: false,
                message: this.$i18n.t("wait")
            }
        },
        mounted(){
            console.log(this)
            console.log(this.$i18n);
        },
        methods: {
            async onDecode (sku) {
                console.log(sku);
                if (sku.length > 0){
                    try {
                        const response = await axios.get(
                            "/product?sku="+sku+"&code="+this.$i18n.locale,
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json'
                                }
                            }
                        );
                        // JSON responses are automatically parsed.
                        console.log(response.data)
                        this.product_data = response.data;
                        this.has_product = true;
                        this.current_sku = sku;
                    } catch (error) {
                        console.log(error);
                    }
                }
            },
            changeLang (locale) {
                this.$i18n.locale = locale
            },
            getPrice(){
                if (this.has_product){
                    return "€ "+parseFloat(this.product_data.price).toFixed(2);
                } else {
                    return "€ --";
                }
            },
            toggleInfo() {
                this.state_scan = !this.state_scan
            },
            buttonText() {
                return this.state_scan ? this.$i18n.t("more_info") : this.$i18n.t("scan")
            }
        }
    }
</script>

<template>
    <div class="header">
        <img src="https://www.eamf.lv/static/frontend/AMFurnitura/theme/lv_LV/images/logo_amf.svg" />
        <span>{{ $t("title") }}</span>
        <div class="lang">
            <div v-for="locale in $i18n.availableLocales" @click="changeLang(locale)">{{locale}}</div>
        </div>
    </div>
    <div v-if="state_scan" class="qr">
        <QrStream @decode="onDecode"></QrStream>
    </div>
    <div v-else class="qr info">
        <img class="prodimg" :src="this.product_data.image"/>
        <span>SKU: {{ this.current_sku }}</span>
        <span class="desc" v-html="this.product_data.description"></span>
    </div>
    <div class="result">
        <div class="vertical-center">
            <span class="prodinfo" v-if="this.has_product">{{ this.product_data.name }}</span>
            <span class="prodinfo" v-else>{{ $t("wait") }}</span>
        </div>
        <div class="down">
            <span class="price">{{ this.getPrice() }}</span>
            <v-btn @click="toggleInfo" :disabled="!has_product">{{ this.buttonText() }}</v-btn>
        </div>
    </div>
</template>

<style>
    #app {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        background-color: white;
        position: absolute;
    }

    .header {
        height: 100px;
    }
    
    .header img {
        width: 100px;
        margin: 10px 0 0 15px;
    }

    .header span {
        text-align: center;
        display: inline-block;
        width: calc(100vw - 115px);
        position: relative;
        top: -13px;
        font-weight: bold;
    }

    .lang {
        width: 100%;
        display: flex;
        justify-content: space-around;
        text-transform: uppercase;
    }

    .qr {
        height: calc(85vh - 100px);
    }

    .qr .qr-stream-camera {
        object-fit: contain;
    }

    .info {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
    }

    .info span {
        text-align: center;
    }

    .info .desc {
        font-size: 11px !important;
    }

    .prodimg {
        /* height: 20vh; */
    }

    .result {
        height: 15vh;
        background-color: #ff7339;
        color: white;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .vertical-center {
        position: relative;
        height: 9vh;
    }

    .prodinfo {
        display: inline-block;
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 100%;
        left: 0;
    }

    .down {
        height: 6vh;
        display: flex;
        justify-content: space-around;
    }

    .price {
        padding-top: 5px;
    }
    
    .v-btn__content {
        position: relative;
        top: 3px;
    }

</style>